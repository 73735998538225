import { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

function RsvpDetails () {
    const [confirmedGuestsCount, setConfirmedGuestsCount] = useState(0);
    const [steakCount, setSteakCount] = useState(0);
    const [salmonCount, setSalmonCount] = useState(0);
    const [chickenCount, setChickenCount] = useState(0);
    const [pastaCount, setPastaCount] = useState(0);

    useEffect(() => {
        
        const guestsCollectionRef = collection(db, "guests");
        const foodsCollectionRef = collection(db, "foods");

        const getDatas = async () => {
            
            try {
                const guestsQuery = await getDocs(guestsCollectionRef);
                const guestsData = guestsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    .sort((a, b) => {
                        if (a.lastName > b.lastName) {
                            return 1;
                        }
                        if (a.lastName < b.lastName) {
                            return -1;
                        }
                        return 0; 
                    });
                const confirmedGuests = guestsData.filter(g => g.isConfirmed === true);
                setConfirmedGuestsCount(confirmedGuests.length);

                const foodsQuery = await getDocs(foodsCollectionRef);
                const foodsData = foodsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

                foodsData.forEach((food) => {
                    switch(food.name.toLowerCase())
                    {
                        case "filet mignon": {
                            setSteakCount(confirmedGuests.filter(g => g.food.id === food.id).length);
                            break;
                        }
                        case "salmon": {
                            setSalmonCount(confirmedGuests.filter(g => g.food.id === food.id).length);
                            break;
                        }
                        case "chicken": {
                            setChickenCount(confirmedGuests.filter(g => g.food.id === food.id).length);
                            break;
                        }
                        case "pasta (kids)": {
                            setPastaCount(confirmedGuests.filter(g => g.food.id === food.id).length);
                            break;
                        }
                        default:
                            break;
                    }
                })

                
                console.log(guestsQuery.docs.map((doc) => ({ firstName: doc.data().firstName, lastName: doc.data().lastName, foodName: foodsData.find((food) => food.id === doc.data().food.id)?.name}))
                            .sort((a, b) => {
                        if (a.lastName > b.lastName) {
                            return 1;
                        }
                        if (a.lastName < b.lastName) {
                            return -1;
                        }
                        return 0; 
                    }))
            }
            catch (response) {
               console.log(response);
            }
        };

        getDatas();
    }, []);

    return (
        <div className="Card">
            <div className="Card-body">
                <table>
                    <tbody>
                        <tr>
                            <td>Number of confirmed guests</td>
                            <td>{confirmedGuestsCount}</td>
                        </tr>
                        <tr>
                            <td>Filet mignon orders</td>
                            <td>{steakCount}</td>
                        </tr>
                        <tr>
                            <td>Salmon orders</td>
                            <td>{salmonCount}</td>
                        </tr>
                        <tr>
                            <td>Chicken orders</td>
                            <td>{chickenCount}</td>
                        </tr>
                        <tr>
                            <td>Pasta orders</td>
                            <td>{pastaCount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RsvpDetails;
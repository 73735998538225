import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { db } from '../firebase-config';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { convertFoodsToDropdownSelectItems } from '../utils/mapper'
import { Message } from 'primereact/message';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import '../css/Rsvp.css';

function Rsvp() {
    const [guests, setGuests] = useState([]);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [showSelectGuestDialog, setShowSelectGuestDialog] = useState(false);
    const [showCancelConfirmationDialog, setCancelConfirmationDialog] = useState(false);
    const [selectedFood, setSelectedFood] = useState(null);
    const [foodsDropdown, setFoodsDropdown] = useState([]);
    const [firebaseError, setFirebaseError] = useState(false);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [confirmedGuestsCount, setConfirmedGuestsCount] = useState(0);

    useEffect(() => {
        
        const guestsCollectionRef = collection(db, "guests");
        const foodsCollectionRef = collection(db, "foods");

        const getDatas = async () => {
            
            try {
                const guestsQuery = await getDocs(guestsCollectionRef);
                const guestsData = guestsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    .sort((a, b) => {
                        if (a.lastName > b.lastName) {
                            return 1;
                        }
                        if (a.lastName < b.lastName) {
                            return -1;
                        }
                        return 0; 
                    });
                setGuests(guestsData);
                setConfirmedGuestsCount(guestsData.filter(g => g.isConfirmed === true).length);

                const foodsQuery = await getDocs(foodsCollectionRef);
                const foodsData = foodsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setFoodsDropdown(convertFoodsToDropdownSelectItems(foodsData));

                setFirebaseError(false);
            }
            catch (response) {
                setFirebaseError(true);
            }
        };

        getDatas();
        initFilters();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'firstName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'lastName': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        });
        setGlobalFilterValue('');
    }

    const renderTableHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h3>Guests <br/>{confirmedGuestsCount} / {guests.length}</h3>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Name Search" />
                </span>
            </div>
        )
    }

    const tableHeader = renderTableHeader();

    const lastNameBodyTemplate = (rowData) => {
        return <span className='FamilyName'> {rowData.lastName} </span>;
    }

    const isConfirmedBodyTemplate = (rowData) => {
        return rowData.isConfirmed ? 
            <button onClick={() => { setSelectedGuest(rowData); setCancelConfirmationDialog(true); }} className='FamilyNameBtn confirmed'>Confirmed</button> : 
            <button onClick={() => { setSelectedGuest(rowData); setShowSelectGuestDialog(true); }} className="unconfirmed FamilyNameBtn">
                Pending
            </button>;
    }

    const onHideDialog = () => {
        setSelectedFood(null);
        setSelectedGuest(null);
        setShowSelectGuestDialog(false);
        setCancelConfirmationDialog(false);
    };

    const dialogHeader = (
        <div className='p-dialog-titlebar'>
            {selectedGuest?.firstName} {selectedGuest?.lastName}
        </div>
    )

    const cancelConfirmationDialogHeader = (
        <div className='p-dialog-titlebar'>
            Cancel your rsvp?
        </div>
    );

    const onSubmit = async () => {

        if(!selectedFood)
            return;

        const userDoc = doc(db, "guests", selectedGuest.id);
        const foodDoc = doc(db, "foods", selectedFood);
        const newFields = { food: foodDoc, isConfirmed: true };
        await updateDoc(userDoc, newFields);

        onHideDialog();

        const guestsCollectionRef = collection(db, "guests");
        const guestsQuery = await getDocs(guestsCollectionRef);
        const guestsData = guestsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            .sort((a, b) => {
                if (a.lastName > b.lastName) {
                    return 1;
                }
                if (a.lastName < b.lastName) {
                    return -1;
                }
                return 0; 
            });
        setGuests(guestsData);
        setConfirmedGuestsCount(guestsData.filter(g => g.isConfirmed === true).length);
    };

    const onCancelRsvp = async () => {
        const userDoc = doc(db, "guests", selectedGuest.id);
        const newFields = { isConfirmed: false };
        await updateDoc(userDoc, newFields);

        onHideDialog();

        const guestsCollectionRef = collection(db, "guests");
        const guestsQuery = await getDocs(guestsCollectionRef);
        const guestsData = guestsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            .sort((a, b) => {
                if (a.lastName > b.lastName) {
                    return 1;
                }
                if (a.lastName < b.lastName) {
                    return -1;
                }
                return 0; 
            });
        setGuests(guestsData);
        setConfirmedGuestsCount(guestsData.filter(g => g.isConfirmed === true).length);
    }

    return (
        <div className='Card'>
            { firebaseError ?  <Message severity="error" text="Unexpected error occured. Please try again tomorrow or contact Nikki." style={ {width: '100%'}}/> : 
                <div className='Card-body'>
                    <DataTable value={guests} responsiveLayout="stack" breakpoint="960px" header={tableHeader} style={{width: '100%', height: '100%', overflow: 'auto'}}
                        dataKey="id" filters={filters} filterDisplay="menu" globalFilterFields={['firstName', 'lastName']}>
                        <Column field="lastName" header="Family Name" body={lastNameBodyTemplate}></Column>
                        <Column field="firstName" header="Name"></Column>
                        <Column field="isConfirmed" header="RSVP" body={isConfirmedBodyTemplate} align="center"></Column>
                    </DataTable>
                    <Dialog visible={showSelectGuestDialog} onHide={onHideDialog} header={dialogHeader} breakpoints={{'960px': '75vw'}} style={{"minWidth": "250px"}} modal>
                        <div className='Dialog-body'>
                            <div className='Dialog-body-item'>
                                <Dropdown style={{"minWidth": "200px"}} value={selectedFood} options={foodsDropdown} onChange={(food) => setSelectedFood(food.value)} placeholder="Select your meal"/>
                            </div>
                        </div>
                        <div className='Dialog-footer'>
                            <Button onClick={onSubmit} label="Confirm" className="p-button-raised p-button-rounded p-button-success" />
                            <Button onClick={onHideDialog} label="Cancel" className="p-button-raised p-button-rounded p-button-danger" />
                        </div>
                    </Dialog>

                    <Dialog visible={showCancelConfirmationDialog} onHide={onHideDialog} header={cancelConfirmationDialogHeader} breakpoints={{'960px': '75vw'}} style={{"minWidth": "250px"}} modal>
                        <div className='Cancel-Dialog-footer'>
                            <Button onClick={onCancelRsvp} label="Cancel RSVP" className="p-button-raised p-button-rounded p-button-danger" />
                        </div>
                    </Dialog>
                </div>
            }
        </div>
    );
}

export default Rsvp;
import Home from './components/home'
import Rsvp from './components/Rsvp'
import { HashRouter, Switch, Route } from "react-router-dom";
import RsvpDetails from './components/RsvpDetails';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/rsvp' exact component={Rsvp} />
        <Route path='/details' exact component={RsvpDetails} />
      </Switch>
    </HashRouter>
  );
}

export default App;

import '../css/home.css';
import { Link } from "react-router-dom";

function Home() {
  return (
      <div className="Card">
        <img className="flower" src={require('../img/flower.png')} alt="flower" />
        <div className="Card-body">
          <Link className='RsvpLink' to='/rsvp'>
          <div className="Names">
            <h1>Laica Daileg</h1>
            <p>&</p>
            <h1>Paolo Hiwatig</h1>
          </div>
          <div className='MainpicContainer'>
            <img className="Mainpic" src={require('../img/mainpic.jpg')} alt="couple" />
          </div>
          <div>
            <p>
              request your presence at our wedding <br/>
              <span className="Date">February 4<sup>th</sup>, 2024</span> <br/>
              at three in the afternoon
            </p>
            <p>
              <strong>Radisson Hotel Montreal Airport</strong>
              <br/>
              6500 De la Côte-de-Liesse Rd, <br />
              Saint-Laurent, Quebec H4T 1E3
            </p>

            <p>
              Kindly <span className="Link">RSVP</span> by December 10<sup>th</sup>, 2023
            </p>

            <p className='GiftNotes'>
              <strong>
                Your presence at our wedding is the greatest
                gift of all. However, should you wish to bless
                us with a gift, a wishing well will be available
                at the reception.
              </strong>
            </p>
          </div>
          </Link>
        </div>
      </div>
  );
}

export default Home;
